import { Component } from '@angular/core';

import { AuthService, RotatorService } from './_services';
import { User } from './_models';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    displayTime: number;
    dashboardDisplay: string;

    constructor(private authenticationService: AuthService, private _rotator: RotatorService) {
        this.authenticationService.user.subscribe(x => this.user = x);

    }

    ngOnInit() {
        this._rotator.displayTime$.subscribe(time => this.displayTime = time);   
        this._rotator.dashboardDisplay$.subscribe(display => this.dashboardDisplay = display);      
      }

    
}