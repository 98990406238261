import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResolverService } from '@app/_services';
import { OverviewComponent, LoginComponent } from '@app/_components';
import { AuthGuard } from '@app/_helpers';

const routes: Routes = [
    { 
        path: 'overview/:siteCode', 
        component: OverviewComponent,
        resolve: { resolveDashboardData: ResolverService }, 
        canActivate: [AuthGuard] 
    },
    
    { path: 'login', component: LoginComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: 'overview/ALL' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }