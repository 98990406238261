import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Dashboard } from '@app/_models';
import { FormatterService, RotatorService, AuthService } from '@app/_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  dashboardData: Dashboard;
  formater: FormatterService;
  timer: Subscription ;
  displayTime: number;
  dashboardDisplay: string;

  constructor(private _route: ActivatedRoute, private _formatter: FormatterService, 
      private _rotator: RotatorService, private _auth: AuthService ) {
    
  }

  ngOnInit() {

    this.dashboardData = this._route.snapshot.data["resolveDashboardData"];
    this._rotator.displayTime$.subscribe(time => this.displayTime = time); 
    this._rotator.dashboardDisplay$.subscribe(display => this.dashboardDisplay = display); 
    this.formater = this._formatter;
    
  }

  dashNav(direction) {

    if (direction == 'left') {

      this._rotator.previousRoute();

    } else if (direction == 'right') {

      this._rotator.nextRoute();
      
    }
  }
  logout() {
    this._auth.logout();
    
  }

}