import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Routes} from '@app/_models';
import { ApiService } from '@app/_services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { timer, interval } from 'rxjs';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class RotatorService {

  routeList: Routes[];
  routeLength: number;
  currentRoute: number;

  private displayTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  displayTime$: Observable<number> = this.displayTime.asObservable();
  private dashboardDisplay: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  dashboardDisplay$: Observable<string> = this.dashboardDisplay.asObservable();

  private seconds: number;
  private delay: number = 1500;
  private t: Observable<number> = timer(0, this.delay);
  //private t: Observable<number> = interval(this.delay);
  private timer: Subscription;
  private isRunning: number = 0;
  
  constructor(private _router: Router, private http: HttpClient, private _apiService: ApiService) {


  }

  ngOnInit() {
    
    //this.currentRoute = 0;
    //this.initialize();
    //this.loadRoutes();

  }

  startRotator(): void {

      this.isRunning = 1;
      this.currentRoute = 0;
      this.initialize();
      this.loadRoutes();
      this.dashboardDisplay.next("Motrolix Overview");

  }

  checkRotator(): number {
    return this.isRunning;
  }

  initialize(): void {
      this.seconds = 15;
      this.timer = this.t
      .subscribe(time => {
        this.displayTime.next(this.seconds - time)
          //console.log(this.displayTime)

          if ( time == this.seconds ) {

            this.nextRoute()

          }
      });
  }  

  refreshTimer(): void {
    
    this.timer.unsubscribe();
    this.initialize();
  }

  stopTimer(): void {
    this.timer.unsubscribe();
  }

  addSeconds(seconds: number) {

    this.seconds = this.seconds + seconds;

  }

  nextRoute() {

    if ( this.currentRoute < this.routeLength - 1 ) {
      this.currentRoute = this.currentRoute + 1;
    } else {
      this.currentRoute = 0;
    }

    this.changeRoute();
    
  }

  previousRoute() {

    if ( this.currentRoute == 0 ) {
      this.currentRoute = this.routeLength - 1 ;
    } else {
      this.currentRoute = this.currentRoute - 1;
    }

    this.changeRoute();
    
  }

  topRoute() {

    this.currentRoute = 0;
    this.changeRoute();
    
  }

  changeRoute() {

    var component = this.routeList[this.currentRoute]["component"];
    var siteCode = this.routeList[this.currentRoute]["siteCode"];
    var nextUrl = '/' + component + '/' + siteCode;

    this._router
      .routeReuseStrategy
      .shouldReuseRoute = function () {
          return false;
    };

    this._router.navigate([nextUrl])
    this.refreshTimer();
    this.dashboardDisplay.next(this.routeList[this.currentRoute].dashboardDisplay);

  }

  loadRoutes() {

    this._apiService.getRouteData()
      .subscribe((data: Routes[]) => (this.routeList = data, this.routeLength = data.length ),(err) => console.error(err));
      

  }

}
