import { AuthService, RotatorService } from '@app/_services';


export function appInitializer(authService: AuthService, rotatorService: RotatorService) {
    
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        authService.cookieLogin()
            .subscribe()
            .add(resolve);
    });
    
}