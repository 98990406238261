import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  constructor() { }

  public getColor(amount, goal) {
    var amountNumber = Number(amount);
    var goalNumber = Number(goal);

    if ( amountNumber >= goalNumber ) {
      return 'green';
    } else if ( amountNumber > ( goalNumber * .8) ) {
      return 'orange';
    } else {
      return 'red';
    }

  }
}
