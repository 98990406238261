import { Directive, HostListener } from '@angular/core';
import { RotatorService } from '@app/_services';


@Directive({
  selector: '[appArrowkey]'
})
export class ArrowkeyDirective {

  rotator: RotatorService;

  constructor(rotator: RotatorService) {
    this.rotator = rotator;
   }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      if (event.key == "ArrowUp") {
        //console.log("Going Up!");
        this.rotator.topRoute();

      } else if (event.key == "ArrowLeft") {
        //console.log("Going Left!");
        this.rotator.previousRoute();

      } else if (event.key == "ArrowRight") {
        //console.log("Going Right!");
        this.rotator.nextRoute();

      } else if (event.key == "ArrowDown") {
        //console.log("Going Down!");
        this.rotator.addSeconds(30);

      } else {
        //console.log(event);
      }
  }

  getColor(amount, goal) {
    if (amount > goal) {
      return 'green';
    } else if (amount > (goal * .8)) {
      return 'orange';
    } else {
      return 'red';
    }

  }

}
