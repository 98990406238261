<!-- nav -->

<div id="header_container" class="containter">
    <div class="header_left">Visitors: {{ dashboardData.currentVisitors }} </div>
    <div class="header_center">{{ dashboardDisplay }} {{ displayTime }} </div>
    <div class="header_right">{{ dashboardData.dashboardDate }} <span class="logout"><a (click)="logout()">Logout</a></span></div>	
  </div>



    <div class="row">

        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.currentPosts, dashboardData.currentPostGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Today's Posts</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.currentPosts, dashboardData.currentPostGoal)">{{ dashboardData.currentPosts | number }}/{{ dashboardData.currentPostGoal }}</span></h2>
                    <h2 class="huge_stat_body"><span >{{ dashboardData.todayPostsTrend | number : '1.0-0' }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left" [style.color]="formater.getColor(dashboardData.currentNewsPosts, dashboardData.currentNewsPostGoal)"><a (click)="dashNav('left')">N:{{ dashboardData.currentNewsPosts | number }}/{{ dashboardData.currentNewsPostGoal }}</a></span>
                        <span style="float:right" [style.color]="formater.getColor(dashboardData.currentContentPosts, dashboardData.currentContentPostGoal)"><a (click)="dashNav('right')">C:{{ dashboardData.currentContentPosts | number }}/{{ dashboardData.currentContentPostGoal }}</a></span>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.currentPageViews, dashboardData.currentPageViewGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Today's Pageviews</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.currentPageViews, dashboardData.currentPageViewGoal)">{{ dashboardData.currentPageViews | number }}</span></h2>
                    <h2 class="huge_stat_body"><span >{{ dashboardData.todayPageViewTrend | number : '1.0-0'  }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.currentRpm, dashboardData.currentRpmGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Today's RPM</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.currentRpm, dashboardData.currentRpmGoal)">{{ dashboardData.currentRpm | currency:'USD':'symbol'}}</span></h2>
                    <h2 class="huge_stat_body"><span >{{ dashboardData.currentRpm / dashboardData.currentRpmGoal | percent }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.currentRevenue, dashboardData.currentRevenueGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Today's Revenue</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.currentRevenue, dashboardData.currentRevenueGoal)">{{ dashboardData.currentRevenue | currency:'USD':'symbol':'1.0-0'}}</span></h2>
                    <h2 class="huge_stat_body"><span >{{ dashboardData.todayRevenueTrend | currency:'USD':'symbol':'1.0-0' }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
	</div>


    <div class="row">

        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.yesterdayPosts, dashboardData.yesterdayPostGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Yesterday's Posts</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.yesterdayPosts, dashboardData.yesterdayPostGoal)">{{ dashboardData.yesterdayPosts | number }}/{{ dashboardData.yesterdayPostGoal }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left" [style.color]="formater.getColor(dashboardData.yesterdayNewsPosts, dashboardData.yesterdayNewsPostGoal)">N:{{ dashboardData.yesterdayNewsPosts | number }}/{{ dashboardData.yesterdayNewsPostGoal }}</span>
                        <span style="float:right" [style.color]="formater.getColor(dashboardData.yesterdayContentPosts, dashboardData.yesterdayContentPostGoal)">C:{{ dashboardData.yesterdayContentPosts | number }}/{{ dashboardData.yesterdayContentPostGoal }}</span>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.yesterdayPageViews, dashboardData.yesterdayPageViewGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Yesterday's Pageviews</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.yesterdayPageViews, dashboardData.yesterdayPageViewGoal)">{{ dashboardData.yesterdayPageViews | number }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.yesterdayRpm, dashboardData.yesterdayRpmGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Yesterday's RPM</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.yesterdayRpm, dashboardData.yesterdayRpmGoal)">{{ dashboardData.yesterdayRpm | currency:'USD':'symbol'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.yesterdayRevenue, dashboardData.yesterdayRevenueGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">Yesterday's Revenue</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.yesterdayRevenue, dashboardData.yesterdayRevenueGoal)">{{ dashboardData.yesterdayRevenue | currency:'USD':'symbol':'1.0-0'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
	</div>

    <div class="row">

        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.mtdPosts, dashboardData.mtdPostGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">MTD's Posts</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.mtdPosts, dashboardData.mtdPostGoal)">{{ dashboardData.mtdPosts | number }}/{{ dashboardData.mtdPostGoal }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left" [style.color]="formater.getColor(dashboardData.mtdNewsPosts, dashboardData.mtdNewsPostGoal)">N:{{ dashboardData.mtdNewsPosts | number }}/{{ dashboardData.mtdNewsPostGoal }}</span>
                        <span style="float:right" [style.color]="formater.getColor(dashboardData.mtdContentPosts, dashboardData.mtdContentPostGoal)">C:{{ dashboardData.mtdContentPosts | number }}/{{ dashboardData.mtdContentPostGoal }}</span>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.mtdPageViews, dashboardData.mtdPageViewGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">MTD's Pageviews</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.mtdPageViews, dashboardData.mtdPageViewGoal)">{{ dashboardData.mtdPageViews | number }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.mtdRpm, dashboardData.mtdRpmGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">MTD's RPM</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.mtdRpm, dashboardData.mtdRpmGoal)">{{ dashboardData.mtdRpm | currency:'USD':'symbol'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.mtdRevenue, dashboardData.mtdRevenueGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">MTD's Revenue</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.mtdRevenue, dashboardData.mtdRevenueGoal)">{{ dashboardData.mtdRevenue | currency:'USD':'symbol':'1.0-0'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.eomPostTrend, dashboardData.eomPostGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">EOM's Posts</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.eomPostTrend, dashboardData.eomPostGoal)">{{ dashboardData.eomPostTrend | number:'1.0-0' }}/{{ dashboardData.eomPostGoal }}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left" [style.color]="formater.getColor(dashboardData.eomNewsPostTrend, dashboardData.eomNewsPostGoal)">N:{{ dashboardData.eomNewsPostTrend  | number:'1.0-0'}}/{{ dashboardData.eomNewsPostGoal  | number:'1.0-0'}}</span>
                        <span style="float:right" [style.color]="formater.getColor(dashboardData.eomContentPostTrend, dashboardData.eomContentPostGoal)">C:{{ dashboardData.eomContentPostTrend | number:'1.0-0' }}/{{ dashboardData.eomContentPostGoal  | number:'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.eomPageViewTrend, dashboardData.eomPageViewGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">EOM's Pageviews</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.eomPageViewTrend, dashboardData.eomPageViewGoal)">{{ dashboardData.eomPageViewTrend | number:'1.0-0'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.eomRpmTrend, dashboardData.eomRpmGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">EOM's RPM</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.eomRpmTrend, dashboardData.eomRpmGoal)">{{ dashboardData.eomRpmTrend | currency:'USD':'symbol'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-md-4 col-xl-3 dashboard">
            <div class="card order-card" [style.box-shadow]="'0 0 10px ' + formater.getColor(dashboardData.eomRevenueTrend, dashboardData.eomRevenueGoal)">
                <div class="card-block">
                    <h4 class="huge_stat_title">EOM's Revenue</h4>
                    <h2 class="huge_stat_body"><span [style.color]="formater.getColor(dashboardData.eomRevenueTrend, dashboardData.eomRevenueGoal)">{{ dashboardData.eomRevenueTrend | currency:'USD':'symbol':'1.0-0'}}</span></h2>
                    <div class="huge_stat_footer" style="color: black">
                        <span style="float:left">0 </span>
                        <span style="float:right"> 0</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
