import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { throwError } from 'rxjs';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class ResolverService implements Resolve<any> {

  constructor(private _apiService: ApiService,  private _router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    var component = route.url[0].path;
    var siteCode = route.params.siteCode;
    //console.log("Resolver says: resolve() firing");
    return this._apiService.getDashboardData(component, siteCode).pipe(catchError((err) => {
      throw err;

    }))
  

  }
}