import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dashboard, User, Routes } from '@app/_models';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@app/_services/user.service';


const apiUrl = 'http://dashboard.motrolix.com/api/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private _router: Router, private _user: UserService ) { }

  getDashboardData(component: string, siteCode: string) {


    return this.http.get<Dashboard[]>(apiUrl + component + '/' + siteCode, { } )
    
  }

  getRouteData() {
    
    return this.http.get<Routes[]>(apiUrl + 'routeList');
    
  }

}
